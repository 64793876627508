@import 'vars';

.mainContainer {
  &.mainContainerCol {
    margin-top: 49px;
    width: 416px;
  }
  &.mainContainerRow {
    padding: 0 80px;
    width: 100%;
    margin-bottom: 64px;
  }
}

.header {
  color: $blue;
  margin: 0 0 32px 0;

  @extend .semiboldH5;
}

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  & > div:nth-of-type(2) {
    margin: 0 16px;
  }
}

.baner {
  align-self: center;
  p {
    margin: 0 0 8px 0;
    color: #b4b1b1;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
  }
}

@media screen and (max-width: 1025px) {
  .mainContainer {
    &.mainContainerRow {
      padding: 0 16px;
    }
  }
}

@media (max-width: 980px) {
  .mainContainer {
    width: 328px;
    &.mainContainerCol {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 16px;
      margin: 0 auto;
      .header {
        align-self: flex-start;
      }
    }
    &.mainContainerRow {
      flex-direction: row;
      margin-bottom: 0;
    }
  }
  .column {
    align-items: center;
    width: 328px;
    justify-content: center;
    margin: auto;
  }
}
