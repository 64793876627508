@import 'vars';

.playerCard {
  height: 312px;
  width: 376px;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  p {
    color: $white;
    margin: 0;
  }
  .cardImage {
    height: 232px;
    width: 100%;
    flex-grow: 1;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .insideContainer {
    position: relative;
    width: 100%;
    z-index: 100;
    text-align: left;
  }

  .hour {
    padding: 0px 8px;
    background: #3b5481;
    position: absolute;
    top: -40px;
    left: 16px;
    height: 24px;
  }
  .title {
    padding: 16px;
    background: $blueGradient;
  }
  .playIcon {
    width: 80px;
    height: 80px;
    top: -156px;
    position: absolute;
  }
}
@media screen and (max-width: 1365px) {
  .playerCard {
    height: 240px;
    width: 340px;
    margin: 0 16px;
    .cardImage {
      background-position: top !important;
    }
    .playIcon {
      top: -104px;
    }
  }
}

@media screen and (max-width: 950px) {
  .playerCard {
    height: 240px;
    width: 248px;
    margin: 0 16px;
    .cardImage {
      background-position: top !important;
    }
    .playIcon {
      top: -104px;
    }
  }
}
