@import 'vars';

.adContainer {
  width: 1280px;
  min-height: 384px;
  border-radius: 16px;
  background: linear-gradient(180deg, rgba(36, 42, 53, 0) 6.25%, #242a35 75%);
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-bottom: 32px;
  border: 1px solid #e9eef5;
}

.banerContainer {
  margin: 0 auto 27px auto;
  align-self: center;
  p {
    margin: 0 0 8px 0;
    color: #b4b1b1;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
  }
  img {
    margin: auto;
  }
}

.accordionContainer {
  margin-top: 24px;
}

.headerContainer {
  width: 1280px;
  height: 384px;
  background-position: center !important;
  background-size: cover !important;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 80px 56px 80px;
}

.header {
  color: $white;
  @extend .boldH0;
  margin: 0;
  width: 100%;
}

.button {
  color: $lightBlue;
  background: transparent;
  border: none;
  text-transform: uppercase;
  @include semibold(14px);
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 40px;
  cursor: pointer;
}

.buttonOpen {
  width: auto;
  padding: 0;
  margin: 0 80px 40px auto;
}

.imgOpen,
.imgClose {
  margin-left: 8px;
}

.imgOpen {
  transform: rotate(180deg);
}

.insideContainer {
  width: 100%;
  padding: 0 80px 0 80px;
  background-color: $black;
  display: flex;
  flex-wrap: wrap;
}

.text {
  @include regular(16px);
  line-height: 24px;
  color: $white;
  margin: 0 0 24px 0;
  span {
    color: $lightBlue;
  }
}
.productionTextContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 70px;
  .text {
    width: 656px;
    margin-right: 136px;
  }
}

.carouselWrapper {
  width: 100%;
}

.carouselAdCard {
  display: flex;
  width: 1120px;
  height: 456px;
  border-radius: 24px;
  overflow: hidden;
  background: $blueGradient;
}

.cardTextContainer {
  padding: 0 40px 40px 40px;
  text-align: left;
  color: $white;
}

.cardHeader {
  @extend .boldH3;
}

.cardText {
  @extend .regularP1;
}

.carouselIMG {
  width: 564px !important;
  object-fit: cover;
  filter: drop-shadow(8px 8px 50px rgba(0, 0, 0, 0.5));
  border-radius: 24px 16px;
}

.downloadCard {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.downloadIcon {
  width: 24px !important;
  height: 24px;
  margin-right: 8px;
}

.downloadLink {
  transition: 0.2s linear;
  &:hover {
    opacity: 0.75;
  }
}

//cards
.cooperate {
  margin: 0 auto 48px auto;
  width: 1440px;
  padding: 0 80px;
}

.cooperateHeader {
  color: $blue;
  @include semibold(24px);
  line-height: 32px;
  text-align: left;
  margin: 32px 0 24px 0;
  align-self: flex-start;
}

.wrapperModule {
  display: flex;
  height: 384px;
  width: 1280px;
  column-gap: 16px;
}

.cardModule {
  min-width: 632px;
  height: 384px;
  background-position: center !important;
  background-size: cover !important;
  border-radius: 16px;
  cursor: pointer;
  display: flex;
  padding: 48px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
  p {
    color: $white;
    @extend .boldH0;
    margin: 0;
    margin-top: auto;
  }
}

.smallCard {
  width: 308px;
  height: 184px;
  background-position: center !important;
  background-size: cover !important;
  border-radius: 16px;
  cursor: pointer;
  display: flex;
  padding: 24px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
  p {
    margin: 0;
    margin-top: auto;
    color: $white;
    @include bold(24px);
    line-height: 32px;
    width: 260px;
  }
}

.smallerContainer {
  display: flex;
  margin-left: 16px;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: space-between;
}

.cardLink {
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.linkShowMore {
  color: $blue;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  margin-top: 20px;
  @extend .semiboldP2;
  cursor: pointer;
  text-transform: uppercase;
  img {
    margin-left: 8px;
  }
}

@media screen and (max-width: 1365px) {
  .adContainer {
    width: calc(100vw - 16px);
  }
  .headerContainer {
    width: 100%;
  }
  .header {
    font-size: 52px;
    line-height: 64px;
  }
}

@media screen and (max-width: 1025px) {
  .cooperate {
    width: 100vw;
    padding: 0 16px;
  }
  .wrapperModule {
    width: 100%;
  }
  .cardModule {
    min-width: 45%;
    width: 100%;
  }
  .banerContainer {
    max-width: 630px;
    img {
      width: 100%;
    }
  }
}

@media screen and (max-width: 979px) {
  .wrapper {
    width: 100%;
    padding: 0 16px;
  }

  .adContainer {
    width: 100%;
    min-height: 216px;
    margin-bottom: 16px;
  }

  .headerContainer {
    width: 100%;
    height: 216px;
    padding: 16px 16px;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-end;
    background-position: center right -15px !important;
  }

  .header {
    width: 100%;
    font-size: 32px;
    line-height: 40px;
  }

  .insideContainer {
    padding: 0 16px;
  }

  .cooperate {
    margin: 0;
    width: 100%;
    padding: 0 16px;
    margin-bottom: 36px;
  }
  .wrapperModule {
    width: 100%;
    // max-width: 360px;
    height: auto;
    margin: auto;
    flex-direction: column;
    justify-content: center;
    row-gap: 8px;
  }
  .cardModule {
    min-width: 100%;
    height: 216px;
    padding: 16px;
    margin: auto;
    p {
      font-size: 32px;
      line-height: 40px;
    }
  }
  .productionTextContainer {
    flex-direction: column;
    margin-bottom: 24px;
    .text {
      width: 100%;
      margin-right: 0;
    }
  }

  .text {
    width: 100%;
    margin-bottom: 16px;
  }

  .productionText {
    margin-bottom: 24px;
  }

  .carouselAdCard {
    margin-top: 16px;
    position: relative;
    flex-direction: column;
    width: 95%;
    min-height: 456px;
    border-radius: 16px;
    transition: min-height 0.3s linear;
  }

  .showMoreWrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 0;
    left: 50%;
    height: 50%;
    transform: translateX(-50%);
    background: linear-gradient(
      180deg,
      rgba(254, 255, 255, 0) 18.8%,
      $blue 65.75%
    );
    width: 100%;
    transition: all 0.2s linear;
    div {
      margin-top: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
      margin-bottom: 16px;
    }
    p {
      color: $white;
      padding: 0;
    }
  }

  .carouselAdCardBigger {
    height: auto;
    transition: all 0.2s linear;
    .showMoreWrapper {
      position: relative;
      background: linear-gradient(
        180deg,
        rgba(254, 255, 255, 0) 90.5%,
        $blue 99.75%
      );
      bottom: -5px;
      transition: all 0.2s linear;
    }
  }

  .carouselIMG {
    height: 150px;
    width: auto !important;
    border-radius: 0px 0px 16px 16px;
  }

  .cardTextContainer {
    padding: 0 16px;
  }

  .cardHeader {
    font-size: 24px;
    line-height: 32px;
    margin: 16px 0;
  }
  .cardText {
    font-size: 14px;
    line-height: 21px;
  }

  .smallerContainer {
    margin-left: 0;
  }
  .cardModule {
    p {
      font-weight: 600;
    }
  }
  .smallCard {
    min-width: 160px;
    max-width: 160px;
    width: 100%;
    height: 184px;
    padding: 16px;
    &:nth-of-type(1),
    &:nth-of-type(2) {
      margin: 8px 0;
    }
    p {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
    }
  }
  .carousel .carousel-dots {
    width: 100%;
  }
  .button {
    justify-content: flex-end;
    margin: 0;
    padding: 0;
  }
  .buttonOpen {
    margin: 0 26px 16px 0;
  }
}
